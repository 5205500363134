<template>
  <div>
    <table-component
      :show-form="false"
      url-create-form="/customers/create"
      url-edit-form="/customers/edit"
      :model="model"
    >
      <template v-slot:actions>
        <a
          :href="downloadUrl"
          class="btn btn-outline-primary"
        >
          <feather-icon icon="DownloadIcon" />
          Descargar formato
        </a>

        <button
          class="btn btn-outline-primary"
        >
          <feather-icon icon="UploadIcon" />
          Importar clientes
        </button>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Customer from '@/dc-it/models/Customer'

export default {
  name: 'UsersView',
  components: { TableComponent },
  data() {
    return {
      model: Customer,
    }
  },
  computed: {
    downloadUrl() {
      return `${process.env.VUE_APP_API_URL}/formatos/formato_clientes.xlsx`
    },
  },
}
</script>

<style scoped>

</style>
